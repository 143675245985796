<template>
  <v-container grid-list-md fluid class="pa-6">
    <v-row v-if="$vuetify.breakpoint.smAndDown" class="hidden-md-and-up mb-3">
      <v-col cols="12">
        <user-profile-card></user-profile-card>
        <div class="text-center mt-3 mb-2">
          <support-us></support-us>
        </div>
        <div class="text-center">
          <feedback></feedback>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card class="border-top-blue" tile>
          <v-card-title
            class="text-h5 app-dark-gray--text font-weight-light py-3"
          >
            <v-icon left>mdi-bell</v-icon>
            <span>{{ $t('notifications.notificationsLast30days') }}</span>
          </v-card-title>
        </v-card>
        <notifications
          class="mt-3"
          :notifications="oldNotifications"
          :onNotificationDeleted="onNotificationDeleted"
        ></notifications>
        <v-pagination
          v-if="oldNotifications.length > 0"
          class="mt-3"
          v-model="page"
          :length="pagesLength"
          :total-visible="7"
          color="app-blue"
          @input="loadNotifications"
        ></v-pagination>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import { GET_OLD_NOTIFICATIONS } from '../store/notifications/types';
import UserProfileCard from '@/components/users/UserProfileCard';
import SupportUs from '@/components/shared/SupportUs';
import Feedback from '@/components/shared/Feedback';
import Notifications from '../components/notifications/Notifications';
import dailyCronMixin from '../mixins/daily.cron.mixin';

export default {
  name: 'notificaitons-view',
  components: {
    UserProfileCard,
    SupportUs,
    Feedback,
    Notifications
  },
  mixins: [dailyCronMixin],
  computed: {
    ...mapGetters(['oldNotifications', 'oldNotificationsCount'])
  },
  data() {
    return {
      page: 1,
      pagesLength: 1,
      limit: 25
    };
  },
  async created() {
    await this.loadNotifications(this.page);
    this.pagesLength = Math.ceil(this.oldNotificationsCount / this.limit);
  },
  methods: {
    async loadNotifications(number) {
      const skip = (number - 1) * this.limit;

      await this.$store.dispatch(GET_OLD_NOTIFICATIONS, {
        limit: this.limit,
        skip
      });
    },
    async onNotificationDeleted() {
      if (this.oldNotificationsCount === 0) {
        this.pagesLength = 1;
      } else {
        this.pagesLength = Math.ceil(this.oldNotificationsCount / this.limit);
      }

      if (this.pagesLength > 0 && this.page >= this.pagesLength) {
        this.page = this.pagesLength;
      }

      await this.loadNotifications(this.page);
    }
  }
};
</script>
