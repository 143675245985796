<template>
  <div>
    <v-list two-line v-if="notifications.length > 0">
      <template v-for="(notification, index) in notifications">
        <v-list-item
          :key="notification.id"
          @click="onNotificationClick(notification)"
        >
          <user-avatar
            class="mr-5"
            :user="notification"
            :size="40"
            textSizeClass="subheading"
          ></user-avatar>

          <v-list-item-content>
            <v-list-item-title>
              <h3 class="subtitle">
                {{ notification.title }}
              </h3>
            </v-list-item-title>
            <v-list-item-subtitle
              v-html="notification.text"
            ></v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action>
            <v-btn
              icon
              ripple
              @click.stop="deleteNotification(notification.id)"
            >
              <v-icon color="app-red">mdi-delete</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>

        <v-divider
          v-if="notifications.length - 1 !== index"
          :key="index"
        ></v-divider>
      </template>
    </v-list>
    <div v-else class="text-center py-4">
      <h1 class="heading app-dark-gray--text font-weight-light">
        {{ $t('notifications.noNotificationsFound') }}
      </h1>
    </div>
  </div>
</template>

<script>
import { DELETE_OLD_NOTIFICATION } from '../../store/notifications/types';
import notificationTypes from '../../enums/notification.types';
import notificationsModalMixin from '../../mixins/notifications.modals.mixin';
import ConfirmModal from '../shared/ConfirmModal';
import UserAvatar from '../shared/UserAvatar';

export default {
  name: 'notifications',
  components: {
    UserAvatar
  },
  props: {
    notifications: {
      type: Array,
      default: () => []
    },
    onNotificationDeleted: {
      type: Function
    }
  },
  computed: {
    notificationTypes() {
      return notificationTypes;
    }
  },
  mixins: [notificationsModalMixin],
  methods: {
    async deleteNotification(id) {
      const confirmed = await this.$root.$modal.show(ConfirmModal, {
        text: this.$i18n.t('notifications.deleteNotificationConfirm')
      });

      if (!confirmed) return;

      await this.$store.dispatch(DELETE_OLD_NOTIFICATION, id);

      if (this.onNotificationDeleted) {
        this.onNotificationDeleted();
      }
    }
  }
};
</script>
